var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pr-lg-16px"
  }, [_c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "tag": "aside",
      "rounded": "",
      "elevation": "4",
      "width": "100%"
    }
  }, [_c('aside-user-card'), _c('aside-navigation')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }