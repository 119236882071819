<template>
    <div class="pr-lg-16px">
        <v-card tag="aside" rounded="" elevation="4" width="100%" class="overflow-hidden">
            <aside-user-card isDashboard />
            <aside-navigation />
        </v-card>
    </div>
</template>

<script>
import AsideUserCard from "../../dumb/aside-user-card.vue";
import AsideNavigation from "./aside-navigation.vue";

export default {
    components: {
        AsideUserCard,
        AsideNavigation,
    },
};
</script>

<style></style>
