var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-list', {
    staticClass: "py-8px py-md-16px"
  }, _vm._l(_vm.items, function (_ref) {
    var to = _ref.to,
      text = _ref.text;
    return _c('v-list-item', _vm._b({
      key: text,
      attrs: {
        "exact": ""
      }
    }, 'v-list-item', {
      to
    }, false), [_vm._v(_vm._s(text))]);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }