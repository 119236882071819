var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.items, function (item, index) {
    return _c('page-section', {
      key: index,
      staticClass: "page-section--xs pb-0",
      class: {
        'pt-0': index == 0
      },
      scopedSlots: _vm._u([_vm.isArray(item) ? {
        key: "containerImmersive",
        fn: function () {
          return _vm._l(item, function (child) {
            return _c('tit-section-row', _vm._b({
              key: child.term
            }, 'tit-section-row', child, false), [child.data ? [_vm._v(" " + _vm._s(child.data) + " ")] : _vm.$slots[child.term] ? _vm._t(child.term) : child.node ? _c('v-node', {
              attrs: {
                "node": child.node
              }
            }) : _vm._e()], 2);
          });
        },
        proxy: true
      } : {
        key: "containerImmersive",
        fn: function () {
          return [_c('tit-section-row', _vm._b({}, 'tit-section-row', item, false), [item.data ? [_vm._v(" " + _vm._s(item.data) + " ")] : _vm.$slots[item.term] ? _vm._t(item.term) : item.node ? _c('v-node', {
            attrs: {
              "node": item.node
            }
          }) : _vm._e()], 2)];
        },
        proxy: true
      }], null, true)
    });
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }