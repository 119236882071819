<template>
    <v-card tile color="primary" class="white--text">
        <div class="pa-18px px-md-10px pa-lg-24px">
            <div class="pb-18px pb-md-24px text-center">
                <div class="font-size-18 font-size-md-20 font-weight-bold line-height-1">{{ user?.name }} 님</div>
                <div class="font-size-14 line-height-1 mt-8px">{{ user?.username }}</div>
            </div>
            <v-divider style="border-color:rgba(255,255,255,0.5) !important; background-color: transparent !important;" />
            <page-section class="page-section--xs pb-0">
                <template #containerImmersive>
                    <v-row v-for="({ term, data, type }, index) in items" :key="term" align="center" justify="space-between" class="row--sm font-size-14" :class="{ 'mt-8px': index != 0 }">
                        <v-col cols="auto">{{ term }}</v-col>
                        <v-col cols="auto" v-if="type == 'chip'">
                            <v-chip x-small outlined color="white">{{ data }}</v-chip>
                        </v-col>
                        <v-col cols="auto" v-else>{{ data }}</v-col>
                    </v-row>
                </template>
            </page-section>
            <page-section class="page-section--xs pb-0">
                <template #containerImmersive>
                    <template v-if="isDashboard">
                        <v-btn block color="white" to="/mypage" class="grey--text text--darken-4">
                            일반회원 페이지로 이동
                        </v-btn>
                    </template>
                    <template v-else-if="userType == USER_TYPES.CONSUMER.value">
                        <promoter-form>
                            <template #activator="{attrs,on}">
                                <v-btn block color="white" v-bind="attrs" v-on="on" class="grey--text text--darken-4">
                                    정회원 신청하기
                                </v-btn>
                            </template>
                        </promoter-form>
                    </template>
                    <template v-else-if="userType == USER_TYPES.PROMOTER.value">
                        <v-btn block color="white" to="/promoter" class="grey--text text--darken-4">
                            정회원 페이지로 이동
                        </v-btn>
                    </template>
                </template>
            </page-section>
        </div>
    </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { USER_TYPES } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import PromoterForm from "../mypage/promoter/promoter-form.vue";

export default {
    components: {
        PageSection,
        PromoterForm,
    },
    props: {
        isDashboard: { type: Boolean, default: false },
    },
    data: () => ({
        USER_TYPES,
    }),
    computed: {
        ...mapState(["user", "payload"]),
        userType() {
            return this.payload?.userType;
        },
        items() {
            const items = [];
            if (this.isDashboard && this.userType == USER_TYPES.PROMOTER.value) items.push({ term: "추천인코드", data: this.user?.code?.code });
            items.push({ term: "회원등급", data: this.user?.title });
            items.push({ term: "적립금", data: `${this.user?.point?.format?.() || 0}원`, type: "chip" });
            if (this.isDashboard && this.userType == USER_TYPES.PROMOTER.value) items.push({ term: "포인트", data: this.user?.score?.format?.() || 0, type: "chip" });
            if (this.isDashboard && this.userType == USER_TYPES.PROMOTER.value) items.push({ term: "보너스", data: `${this.user?.bonus?.format?.() || 0}원`, type: "chip" });

            return items;
        },
        userTypeChanged() {
            return this.user?.type && this.userType && this.user?.type != this.userType;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions(["getUser"]),
        async init() {
            await this.getUser();
            this.validate();
        },
        validate() {
            if (this.userTypeChanged) {
                alert("회원정보가 변동되었습니다. 다시 로그인해주세요.");
                this.$store.dispatch("logout");
            }
        },
    },
};
</script>

<style></style>
