var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "pb-20px"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-row', {
    staticClass: "row--xs",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-select', {
    staticClass: "w-120px",
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "items": _vm.questionTypes,
      "item-text": "text",
      "item-value": "type",
      "placeholder": "문의유형"
    },
    model: {
      value: _vm.questionTypes,
      callback: function ($$v) {
        _vm.questionTypes = $$v;
      },
      expression: "questionTypes"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": ""
    }
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "placeholder": "검색어를 입력하세요"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100 w-sm-80px",
    attrs: {
      "color": "grey"
    },
    on: {
      "click": _vm.getQuestions
    }
  }, [_vm._v("검색")])], 1)], 1)], 1)], 1)], 1), _c('v-data-table', _vm._b({
    staticClass: "v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile v-data-table--tit v-data-table--cursor-pointer",
    attrs: {
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-sort": "",
      "no-data-text": "작성된 문의사항이 없습니다",
      "mobile-breakpoint": "768"
    },
    on: {
      "click:row": function (any, _ref) {
        var item = _ref.item;
        return _vm.showBoard(item);
      }
    },
    scopedSlots: _vm._u([{
      key: `item.subject`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "text-truncate",
          attrs: {
            "data-idx": "5"
          }
        }, [_vm._v(_vm._s(item.subject))])];
      }
    }, {
      key: `item.type`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('span', [_vm._v(_vm._s(item.type))])];
      }
    }, {
      key: `item.createdAt`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('span', [_vm._v(_vm._s(item.createdAt.toDate()))])];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [item.reply ? _c('span', [_vm._v("답변완료")]) : _c('span', {
          staticClass: "grey--text text--lighten-1"
        }, [_vm._v("답변대기")])];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false)), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "to": {
        query: {
          _question: null
        }
      }
    }
  }, [_vm._v("글쓰기")])], 1)], 1)], 1), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }