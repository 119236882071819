<template>
    <v-list class="py-8px py-md-16px">
        <v-list-item v-for="{ to, text } in items" :key="text" v-bind="{ to }" exact>{{ text }}</v-list-item>
    </v-list>
</template>

<script>
const items = [
    { text: "대시보드", to: { path: "/mypage/dashboard" } },
    { text: "주문내역", to: { path: "/mypage/orders" } },
    { text: "장바구니", to: { path: "/mypage/cart/single" } },
    { text: "정기배송", to: { path: "/mypage/regular/orders" } },
    { text: "결제수단", to: { path: "/mypage/cards" } },
    { text: "찜한상품", to: { path: "/mypage/likes" } },
    { text: "적립금 내역", to: { path: "/mypage/points" } },
    { text: "회원정보 수정", to: { path: "/mypage/myinfo" } },
    { text: "배송지목록", to: { path: "/mypage/destinations" } },
    { text: "구매후기", to: { path: "/mypage/reviews" } },
    { text: "문의하기", to: { path: "/mypage/questions" } },
    { text: "회원탈퇴", to: { path: "/mypage/resign" } },
];
export default {
    data: () => ({
        items,
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-list-item {
        z-index: 1;
        font-size: var(--txt-font-size-sm);
        color: var(--v-grey-base);
        &--active {
            font-weight: 500;
            color: var(--v-grey-darken4);
            &::before {
                z-index: -1;
                opacity: 1;
                background-color: var(--v-grey-lighten5);
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    ::v-deep {
        .v-list-item {
            min-height: 56px;
            padding: 0 24px;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
