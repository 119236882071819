<template>
    <v-list class="py-8px py-md-16px">
        <v-list-item v-for="{ to, text } in items" :key="text" v-bind="{ to }">{{ text }}</v-list-item>
    </v-list>
</template>

<script>
const items = [
    { text: "대시보드", to: { path: "/promoter/dashboard" } },
    { text: "보너스 내역", to: { path: "/promoter/bonuses" } },
    { text: "보너스 출금", to: { path: "/promoter/bonuses-withdraw" } },
    { text: "추천회원 구매확정 내역", to: { path: "/promoter/promotee-purchases" } },
    { text: "나의 구매확정 내역", to: { path: "/promoter/promoter-purchases" } },
    { text: "직급 포인트 관리", to: { path: "/promoter/scores" } },
    { text: "계좌정보", to: { path: "/promoter/bank" } },
    { text: "나의 추천회원", to: { path: "/promoter/promotees" } },
    // { text: "회원정보 수정", to: { path: "/promoter/myinfo" } },
    { text: "문의하기", to: { path: "/promoter/questions" } },
];
export default {
    data: () => ({
        items,
    }),
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-list-item {
        z-index: 1;
        font-size: var(--txt-font-size-sm);
        color: var(--v-grey-base);
        &--active {
            font-weight: 500;
            color: var(--v-grey-darken4);
            &::before {
                z-index: -1;
                opacity: 1;
                background-color: var(--v-grey-lighten5);
            }
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    ::v-deep {
        .v-list-item {
            min-height: 56px;
            padding: 0 24px;
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>

