<template>
    <v-container v-bind="$attrs">
        <v-row>
            <v-col cols="12" md="3" v-if="accessToken">
                <promoter-aside v-if="aside == 'promoter'" />
                <mypage-aside v-else />
            </v-col>
            <v-col cols="12" :md="accessToken ? 9 : undefined">
                <div :class="titleSpacingLg ? 'pt-10px pb-16px pt-md-16px pb-md-40px pb-lg-60px' : 'pt-10px pb-16px pt-md-16px pb-md-20px'">
                    <h3 class="tit tit--sm line-height-1">{{ title }}</h3>
                </div>

                <slot />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import MypageAside from "@/components/client/mypage/aside/mypage-aside.vue";
import PromoterAside from "@/components/client/promoter/aside/promoter-aside.vue";
import { mapState } from "vuex";
export default {
    props: {
        title: { type: String },
        titleSpacingLg: { type: Boolean },
        aside: { type: String, default: "" },
    },
    components: {
        MypageAside,
        PromoterAside,
    },
    computed: {
        ...mapState(["accessToken"]),
    },
    mounted() {},
    destroyed() {},
    methods: {},
};
</script>
