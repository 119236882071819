<template>
    <div>
        <div class="pb-20px">
            <v-row align="center" class="row--sm">
                <v-spacer />
                <v-col cols="12" sm="auto">
                    <v-row align="center" class="row--xs">
                        <v-col cols="auto">
                            <v-select v-model="questionTypes" dense outlined hide-details :items="questionTypes" item-text="text" item-value="type" placeholder="문의유형" class="w-120px" />
                        </v-col>
                        <v-col cols="">
                            <v-text-field dense outlined hide-details placeholder="검색어를 입력하세요" />
                        </v-col>
                        <v-col cols="12" sm="auto">
                            <v-btn color="grey" class="w-100 w-sm-80px" @click="getQuestions">검색</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>

        <v-data-table v-bind="{ headers, items }" hide-default-footer disable-filtering disable-sort @click:row="(any, { item }) => showBoard(item)" no-data-text="작성된 문의사항이 없습니다" mobile-breakpoint="768" class="v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile v-data-table--tit v-data-table--cursor-pointer">
            <template #[`item.subject`]="{ item }">
                <div data-idx="5" class="text-truncate">{{ item.subject }}</div>
            </template>
            <template #[`item.type`]="{ item }">
                <span>{{ item.type }}</span>
            </template>
            <template #[`item.createdAt`]="{ item }">
                <span>{{ item.createdAt.toDate() }}</span>
            </template>
            <template #[`item.actions`]="{ item }">
                <span v-if="item.reply">답변완료</span>
                <span v-else class="grey--text text--lighten-1">답변대기</span>
            </template>
        </v-data-table>

        <div class="btn-wrap">
            <v-row class="row--xs">
                <v-spacer />
                <v-col cols="auto">
                    <v-btn color="primary" :to="{ query: { _question: null } }">글쓰기</v-btn>
                </v-col>
            </v-row>
        </div>

        <div class="pagination-wrap">
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="search" />
        </div>
    </div>
</template>

<script>
import PaginationComponent from "../../../pagination-component.vue";

const headers = [
    { text: "제목", value: "subject", align: "center", cellClass: "left" },
    { text: "문의유형", value: "type", align: "center", width: 200 },
    { text: "작성일", value: "createdAt", align: "center", width: 200 },
    { text: "상태", value: "actions", align: "center", width: 120 },
];

export default {
    components: {
        PaginationComponent,
    },
    props: {
        code: { type: String },
    },
    data: () => ({
        filter: {
            searchKey: "subject",
            searchValue: null,
            type: null,
        },

        page: 1,
        pageCount: 0,
        limit: 10,

        questions: [],
        headers,
        questionTypes: ["회원정보 문의", "주문/결제 문의", "상품 문의", "배송 문의", "교환/반품 문의", "이벤트/혜택 문의", "서비스 개선 의견", "기타문의"],
    }),
    computed: {
        items() {
            return [...this.questions];
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.getQuestions();

                this.$nextTick(() => {
                    // 문의내역 - 답변 토글
                    (function() {
                        let question = document.querySelectorAll('.board-table--inquiry [role="button"]'),
                            answer = document.querySelectorAll('.board-table--inquiry [role="rowgroup"].answer');

                        for (let i = 0; i < question.length; i++) question[i].addEventListener("click", toggleInquiry);

                        function toggleInquiry() {
                            let idx = this.getAttribute("data-idx"),
                                toggle = document.querySelector('.board-table--inquiry [role="rowgroup"][data-idx-answer="' + idx + '"]');

                            if (toggle !== null) {
                                if (toggle.getAttribute("data-answer") === "opened") {
                                    for (let i = 0; i < answer.length; i++) answer[i].removeAttribute("data-answer");
                                } else {
                                    for (let i = 0; i < answer.length; i++) answer[i].removeAttribute("data-answer");
                                    toggle.setAttribute("data-answer", "opened");
                                }
                            }
                        }
                    })();
                });
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        async search() {
            try {
                await this.getQuestions();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        getQuestions() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/v1/center/questions", {
                        headers: {
                            skip: (this.page - 1) * this.limit,
                            limit: this.limit,
                        },
                        params: { ...this.filter, code: this.code },
                    });

                    this.pageCount = Math.ceil(res.data.summary.totalCount / this.limit);
                    this.questions = res.data.questions;

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
        showBoard(item) {
            this.$router.push({ query: { _question: item._id } });
        },
    },
};
</script>
