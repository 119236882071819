var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('tit-wrap-small', _vm._b({
    scopedSlots: _vm._u([{
      key: "titleContents",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.term) + " "), _vm.required ? _c('span', {
          staticClass: "red--text"
        }, [_vm._v("*")]) : _vm._e()];
      },
      proxy: true
    }])
  }, 'tit-wrap-small', _vm.$attrs.headerProps, false)), _vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }