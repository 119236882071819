var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._b({}, 'div', {
    loading: _vm.loading
  }, false), [_c('tit-form-section', _vm._b({
    on: {
      "input": _vm.emit
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'tit-form-section', {
    items: _vm.items
  }, false)), _c('page-section', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('terms-of-agreements-short', {
          attrs: {
            "params": _vm.termsParams
          },
          on: {
            "input": _vm.emit
          },
          model: {
            value: _vm.form._terms,
            callback: function ($$v) {
              _vm.$set(_vm.form, "_terms", $$v);
            },
            expression: "form._terms"
          }
        })];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "block": "",
      "x-large": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('save');
      }
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v(" 정회원으로 전환 요청하기 ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }