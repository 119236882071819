var render = function render(){
  var _vm$user, _vm$user2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "white--text",
    attrs: {
      "tile": "",
      "color": "primary"
    }
  }, [_c('div', {
    staticClass: "pa-18px px-md-10px pa-lg-24px"
  }, [_c('div', {
    staticClass: "pb-18px pb-md-24px text-center"
  }, [_c('div', {
    staticClass: "font-size-18 font-size-md-20 font-weight-bold line-height-1"
  }, [_vm._v(_vm._s((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.name) + " 님")]), _c('div', {
    staticClass: "font-size-14 line-height-1 mt-8px"
  }, [_vm._v(_vm._s((_vm$user2 = _vm.user) === null || _vm$user2 === void 0 ? void 0 : _vm$user2.username))])]), _c('v-divider', {
    staticStyle: {
      "border-color": "rgba(255,255,255,0.5) !important",
      "background-color": "transparent !important"
    }
  }), _c('page-section', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return _vm._l(_vm.items, function (_ref, index) {
          var term = _ref.term,
            data = _ref.data,
            type = _ref.type;
          return _c('v-row', {
            key: term,
            staticClass: "row--sm font-size-14",
            class: {
              'mt-8px': index != 0
            },
            attrs: {
              "align": "center",
              "justify": "space-between"
            }
          }, [_c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_vm._v(_vm._s(term))]), type == 'chip' ? _c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_c('v-chip', {
            attrs: {
              "x-small": "",
              "outlined": "",
              "color": "white"
            }
          }, [_vm._v(_vm._s(data))])], 1) : _c('v-col', {
            attrs: {
              "cols": "auto"
            }
          }, [_vm._v(_vm._s(data))])], 1);
        });
      },
      proxy: true
    }])
  }), _c('page-section', {
    staticClass: "page-section--xs pb-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_vm.isDashboard ? [_c('v-btn', {
          staticClass: "grey--text text--darken-4",
          attrs: {
            "block": "",
            "color": "white",
            "to": "/mypage"
          }
        }, [_vm._v(" 일반회원 페이지로 이동 ")])] : _vm.userType == _vm.USER_TYPES.CONSUMER.value ? [_c('promoter-form', {
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref2) {
              var attrs = _ref2.attrs,
                on = _ref2.on;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "grey--text text--darken-4",
                attrs: {
                  "block": "",
                  "color": "white"
                }
              }, 'v-btn', attrs, false), on), [_vm._v(" 정회원 신청하기 ")])];
            }
          }])
        })] : _vm.userType == _vm.USER_TYPES.PROMOTER.value ? [_c('v-btn', {
          staticClass: "grey--text text--darken-4",
          attrs: {
            "block": "",
            "color": "white",
            "to": "/promoter"
          }
        }, [_vm._v(" 정회원 페이지로 이동 ")])] : _vm._e()];
      },
      proxy: true
    }])
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }