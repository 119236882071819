<template>
    <div>
        <tit-wrap-small v-bind="$attrs.headerProps">
            <template #titleContents> {{ term }} <span v-if="required" class="red--text">*</span> </template>
        </tit-wrap-small>
        <slot />
    </div>
</template>

<script>
import TitWrapSmall from "@/components/client/dumb/tit-wrap-small.vue";

export default {
    components: {
        TitWrapSmall,
    },
    props: {
        term: String,
        required: Boolean,
    },
};
</script>
