var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', _vm._b({}, 'v-container', _vm.$attrs, false), [_c('v-row', [_vm.accessToken ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_vm.aside == 'promoter' ? _c('promoter-aside') : _c('mypage-aside')], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": _vm.accessToken ? 9 : undefined
    }
  }, [_c('div', {
    class: _vm.titleSpacingLg ? 'pt-10px pb-16px pt-md-16px pb-md-40px pb-lg-60px' : 'pt-10px pb-16px pt-md-16px pb-md-20px'
  }, [_c('h3', {
    staticClass: "tit tit--sm line-height-1"
  }, [_vm._v(_vm._s(_vm.title))])]), _vm._t("default")], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }